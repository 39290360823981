import { usePageTitle } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import AllZoneListingsComponent from "./AllZoneListingComponents/AllZoneListingsComponent";
import { useAppDispatch } from "app/hooks";
import { actions } from "models/Slices/FilterSlice";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { RBAC, useRbac } from "features/rbac/rbac";
import {
  EventsWithActiveZoneListingsQueryParams,
  IActiveListing,
  IEditInventoryListingDatum,
  IEventsWithActiveZoneListingsResponse,
} from "models/interfaces";
import { AppSetting } from "utils/appSettings";
import { useLocation } from "react-router";
import {
  decodeQueryStringData,
  urlQueryParamsToObject,
} from "utils/commonFunction";
import { Button, Col, message, Row, Switch, Tooltip } from "antd";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { showConfirm } from "utils/commonConfirmation/confirmationPopup";
import { ZonesService } from "services";
import { LoggerService } from "sharedServices";

const ZoneListingsPage: React.FC = () => {
  usePageTitle("Zone Listings");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { hasPermissions } = useRbac();
  const [queryParams, setQueryParams] =
    useState<EventsWithActiveZoneListingsQueryParams>({
      page_size: AppSetting.PAGE_SIZE,
      page_index: AppSetting.DEFAULT_PAGE_INDEX,
      sort_order: "asc",
      sort_column: "days_to_event",
      risk_type: null,
      low_or_no_availability_listings_only: false,
      high_risk_listings_only: false,
    });
  const [searchText, setSearchText] = useState<string | null>(null);
  const [isPageReady, setIsPageReady] = useState(false);
  const [isWidgetFilterParams, setIsWidgetFilterParams] = useState(false);
  const [filterQueryObject, _setFilterQueryObject] = useState<{
    broker_user_Id: string | null;
    lister_user_Id: string | null;
    show_broadcasted_listings?: boolean;
    show_missing_tags?: boolean;
  }>({
    broker_user_Id: null,
    lister_user_Id: null,
    show_broadcasted_listings: true,
    show_missing_tags: false,
  });
  const [filtersAppliedWithValues, setFilterAppliedWithValues] =
    useState<any>(null);
  const [urlParams, setUrlParams] = useState<any>([]);
  const [isFilterDrawerDisabled, setIsFilterDrawerDisabled] = useState(false);
  const [viewMode, setViewMode] = useState<"gridView" | "listView">("listView");
  const [allZoneListings, setAllZoneListings] =
    useState<IEventsWithActiveZoneListingsResponse>();
  const [isUnbroadcastButtonEnabled, setIsUnbroadcastButtonEnabled] =
    useState(false);
  const [saveChangesButtonEnabled, setSaveChangesButtonEnabled] =
    useState(false);
  const [purchaseLineId, setPurchaseLineId] = useState<string | null>(null);
  const [isUnbroadcastFilteredEnabled, setIsUnbroadcastFilteredEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(actions.getAllStatesData());
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }

    if (
      hasPermissions([
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getListerUserData());
    }
  }, []);

  useEffect(() => {
    const locationSearch = location.search;
    if (
      locationSearch &&
      (locationSearch.includes("filter") || locationSearch.includes("search"))
    ) {
      const queryObject = urlQueryParamsToObject(locationSearch);
      if (
        queryObject.isDisabled &&
        decodeQueryStringData(queryObject.isDisabled).disabled === true
      ) {
        setIsFilterDrawerDisabled(true);
      }

      if (queryObject.filter) {
        const decodedQueryParams = decodeQueryStringData(queryObject.filter);
        setQueryParams((prevQueryParams: any) => {
          return {
            ...prevQueryParams,
            ...decodedQueryParams,
          };
        });
        setUrlParams((prevUrlParams: any) => {
          return {
            ...prevUrlParams,
            ...decodedQueryParams,
          };
        });
        setIsWidgetFilterParams(true);
      } else if (queryObject.search) {
        let filterSearchString = decodeQueryStringData(queryObject.search);
        if (
          typeof filterSearchString === "object" &&
          filterSearchString.is_from_metadata === true
        ) {
          setQueryParams({
            ...queryParams,
            search_text: filterSearchString.po_id + "",
            purchase_line_id: filterSearchString.po_line_id,
          });
          setUrlParams({
            ...urlParams,
            event_id: filterSearchString.event_id,
            purchase_id: filterSearchString.po_id,
            purchase_line_id: filterSearchString.po_line_id,
          });
          _setFilterQueryObject((prev: any) => {
            return {
              ...prev,
              show_broadcasted_listings: false
            };
          });
        } else if (filterSearchString) {
          setQueryParams({
            ...queryParams,
            search_text: filterSearchString,
          });
        }
        setPurchaseLineId(
          filterSearchString.po_line_id ? filterSearchString.po_line_id : null
        );
      }
    }
    setIsPageReady(true);
  }, []);

  useEffect(() => {
    let enableCta = false;
    const _events = allZoneListings?.events;
    if (_events) {
      enableCta =
        _events.find((eachEvent) => {
          const _editedActiveListings =
            eachEvent.po_details?.editedActiveListings;
          return _editedActiveListings?.find(
            (eachListing) => eachListing.is_checked
          ) !== undefined
            ? true
            : false;
        }) !== undefined
          ? true
          : false;
    }
    setIsUnbroadcastButtonEnabled(enableCta);
    if (_events) {
      let enableSave =
        _events.find((eachEvent) => {
          const newArray = eachEvent.po_details?.editedActiveListings.map(
            (obj) => {
              if (obj?.is_checked === true || obj?.is_checked === false) {
                const { is_checked, ...newObj } = obj;
                return newObj;
              }
              return obj;
            }
          );

          const oldArray = eachEvent.po_details?.activeListings.map((obj) => {
            if (obj?.is_checked === true || obj?.is_checked === false) {
              const { is_checked, ...newObj } = obj;
              return newObj;
            }
            return obj;
          });

          return JSON.stringify(oldArray) !== JSON.stringify(newArray);
        }) !== undefined
          ? true
          : false;

      let i,
        j,
        k,
        validListPrice = true;
      for (i = 0; i < _events.length; i++) {
        let event = _events[i];
        if (event && event.po_details?.editedActiveListings) {
          for (j = 0; j < event.po_details?.editedActiveListings.length; j++) {
            let editActiveListing: IActiveListing =
              event.po_details?.editedActiveListings[j];
            for (k = 0; k < event.po_details?.activeListings.length; k++) {
              if (
                editActiveListing.inventory_id ===
                  event.po_details?.activeListings[k].inventory_id &&
                editActiveListing.list_price !==
                  event.po_details?.activeListings[k].list_price
              ) {
                if (
                  editActiveListing.list_price <= 0 ||
                  editActiveListing.list_price > 9999
                ) {
                  validListPrice = false;
                  break;
                }
              }
            }
            if (!validListPrice) {
              break;
            }
          }
        }
        if (!validListPrice) {
          break;
        }
      }
      setSaveChangesButtonEnabled(enableSave && validListPrice);
    }
  }, [allZoneListings]);

  const getHeightOfAllZoneListings = useCallback(() => {
    return "calc(100vh - 175px)";
  }, []);

  function countUniquePurchaseLines(jsonArray: any) {
    const uniquePurchaseLineIds = {};

    for (const element of jsonArray) {
      const purchaseLineId = element.purchase_line_id;
      uniquePurchaseLineIds[purchaseLineId] = true;
    }

    return Object.keys(uniquePurchaseLineIds).length;
  }

  const handleTextSearch = (e: any) => {
    e.preventDefault();
    const _queryParams = {
      ...queryParams,
      page_index: AppSetting.DEFAULT_PAGE_INDEX,
      search_text: searchText,
    };
    if (searchText !== null && searchText !== "") {
      setQueryParams(_queryParams);
    }
  };

  const closeSearch = () => {
    setSearchText(null);
    setQueryParams({
      ...queryParams,
      page_index: 1,
      search_text: null,
      purchase_line_id: null,
    });
    setPurchaseLineId(null);
    setUrlParams(null);
  };

  const filterChanged = useCallback(
    (filters: any) => {
      const _queryParams = {
        ...queryParams,
        ...filters,
        page_index: AppSetting.DEFAULT_PAGE_INDEX,
      };
      setQueryParams(_queryParams);
    },
    [queryParams]
  );

  const viewToggleHandler = useCallback(
    (checked: boolean) => {
      setTimeout(() => {
        if (checked) {
          setViewMode("listView");
          setAllZoneListings((prev) => {
            const _prev = prev;
            if (_prev) {
              _prev.events.forEach((eachEvent) => {
                eachEvent.po_details = undefined;
              });
              return _prev;
            }
            return prev;
          });
          setIsUnbroadcastButtonEnabled(false);
        } else {
          setViewMode("gridView");
        }
      }, 180);
    },
    [viewMode]
  );

  const confirmUnbroadcastSelectedHandler = async () => {
    let inventory_ids: number[] = [];
    let purchase_line_ids: number[] = [];
    let broadcast_status = false;
    // get the list of checked inventories and their purchase line ids
    let _events = allZoneListings?.events;
    if (_events) {
      _events.forEach((eachEvent) => {
        const _activeListings = eachEvent.po_details?.editedActiveListings;
        if (_activeListings) {
          _activeListings.forEach((eachListing) => {
            if (eachListing.is_checked) {
              if (eachListing.is_split) {
                inventory_ids.push(...eachListing.inventory_ids);
              } else {
                inventory_ids.push(eachListing.inventory_id);
              }
              purchase_line_ids.push(eachListing.purchase_line_id);
            }
          });
        }
      });
    }
    setIsUnbroadcastButtonEnabled(false);
    const unbroadcastSelectedKey = "unbroadcastSelected";
    try {
      message.loading({
        content: "Un-broadcasting selected inventories...",
        duration: 0,
        key: unbroadcastSelectedKey,
        className: "toastMsg loadingMsg",
      });
      const response = await new ZonesService().bulkUpdateBroadcastStatus({
        inventory_ids: inventory_ids,
        purchase_line_ids: purchase_line_ids,
        broadcast_status: broadcast_status,
      });
      if (response.status === 200) {
        // message.loading({
        //   content:
        //     "Syncing zone listings from SkyBox. This may take a while. You may continue to work in other areas...",
        //   duration: 0,
        //   key: unbroadcastSelectedKey,
        //   className: "toastMsg loadingMsg",
        // });
        // await new ZonesService().refreshZoneInventoryMVW();
        // reset all checkboxes
        setAllZoneListings((prev) => {
          if (prev) {
            const _prev = { ...prev };
            _prev.events.forEach((eachEvent) => {
              const _editedActiveListings =
                eachEvent.po_details?.editedActiveListings;
              const _activeListingsPO = eachEvent.po_details;
              _editedActiveListings?.forEach((eachListing) => {
                if (eachListing.is_checked) {
                  eachListing.is_checked = false;
                  eachListing.broadcasted_inventory_count = 0;
                  eachListing.unbroadcasted_inventory_count =
                    eachListing.inventory_ids?.length;
                  eachListing.broadcast = false;
                }
              });
              if (_activeListingsPO) {
                _activeListingsPO.activeListings = JSON.parse(
                  JSON.stringify(_editedActiveListings)
                );
              }
            });
            return _prev;
          }
          return prev;
        });
      }
      message.success({
        content: `Un-broadcast successful.`,
        duration: 5,
        key: unbroadcastSelectedKey,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      await new LoggerService().log({
        payload: error,
        function_name: "confirmUnbroadcastSelectedHandler",
      });
      message.error({
        key: unbroadcastSelectedKey,
        content: "Un-broadcast failed.",
        duration: 5,
        className: "toastMsg savedFailed",
      });
      setIsUnbroadcastButtonEnabled(true);
    }
  };

  const confirmUnbroadcastFilteredHandler = async (
    broadcastedPOLines: { inventory_id: number; purchase_line_id: number }[]
  ) => {
    let inventory_ids: number[] = [];
    let purchase_line_ids: number[] = [];
    let broadcast_status = false;

    if (broadcastedPOLines) {
      broadcastedPOLines.forEach((eachObj) => {
        inventory_ids.push(eachObj.inventory_id);
        purchase_line_ids.push(eachObj.purchase_line_id);
      });
      // setIsUnbroadcastButtonEnabled(false)
      const unbroadcastSelectedKey = "unbroadcastSelected";
      try {
        message.loading({
          content: "Un-broadcasting filtered inventories...",
          duration: 0,
          key: unbroadcastSelectedKey,
          className: "toastMsg loadingMsg",
        });
        const response = await new ZonesService().bulkUpdateBroadcastStatus({
          inventory_ids: inventory_ids,
          purchase_line_ids: purchase_line_ids,
          broadcast_status: broadcast_status,
        });
        if (response.status === 200) {
          // message.loading({
          //   content:
          //     "Syncing zone listings from SkyBox. This may take a while. You may continue to work in other areas...",
          //   duration: 0,
          //   key: unbroadcastSelectedKey,
          //   className: "toastMsg loadingMsg",
          // });
          // await new ZonesService().refreshZoneInventoryMVW();
          // reset all checkboxes
          setAllZoneListings((prev) => {
            if (prev) {
              const _prev = { ...prev };
              _prev.events.forEach((eachEvent) => {
                const _editedActiveListings =
                  eachEvent.po_details?.editedActiveListings;
                const _activeListingsPO = eachEvent.po_details;
                _editedActiveListings?.forEach((eachListing) => {
                  if (eachListing.broadcast) {
                    eachListing.is_checked = false;
                    eachListing.broadcasted_inventory_count = 0;
                    eachListing.unbroadcasted_inventory_count =
                      eachListing.inventory_ids?.length;
                    eachListing.broadcast = false;
                  }
                });
                if (_activeListingsPO) {
                  _activeListingsPO.activeListings = JSON.parse(
                    JSON.stringify(_editedActiveListings)
                  );
                }
              });
              return _prev;
            }
            return prev;
          });
        }
        setIsUnbroadcastFilteredEnabled(false);
        message.success({
          content: `Un-broadcast successful.`,
          duration: 5,
          key: unbroadcastSelectedKey,
          className: "toastMsg savedSuccess",
        });
      } catch (error: any) {
        await new LoggerService().log({
          payload: error,
          function_name: "confirmUnbroadcastFilteredHandler",
        });
        message.error({
          key: unbroadcastSelectedKey,
          content: "Un-broadcast failed.",
          duration: 5,
          className: "toastMsg savedFailed",
        });
      }
    }
  };

  const cancelOperationHandler = () => {
    message.error({
      content: "Operation cancelled by user.",
      duration: 5,
      className: "toastMsg savedFailed",
    });
  };

  const getBroadcastSelectedConfirmBody = () => {
    return (
      <div>
        <div className="delMessageBody">
          <p>Would you like to un-broadcast all the selected inventories?</p>
        </div>
        <div className="smlErrorTxt">This action cannot be undone.</div>
      </div>
    );
  };

  const getBroadcastedFilteredConfirmBody = () => {
    return (
      <div>
        <div className="delMessageBody">
          <p>
            The listings for events between{" "}
            {filtersAppliedWithValues[1].field_value} and{" "}
            {filtersAppliedWithValues[2].field_value} will be unbroadcasted.
            Total{" "}
            {allZoneListings && allZoneListings?.broadcasted_po_lines != null
              ? countUniquePurchaseLines(allZoneListings?.broadcasted_po_lines)
              : 0}{" "}
            PO lines will be affected. Do you want to continue?
          </p>
        </div>
        <div className="smlErrorTxt">This action cannot be undone.</div>
      </div>
    );
  };

  const getDiscardChangesConfirmBody = () => {
    return (
      <div>
        <div className="delMessageBody">
          <p>All changes will be discarded. Continue?</p>
        </div>
        <div className="smlErrorTxt">This action cannot be undone.</div>
      </div>
    );
  };

  const getSaveChangesConfirmBody = () => {
    return (
      <div>
        <div className="delMessageBody">
          <p>Save all changes?</p>
        </div>
      </div>
    );
  };

  const allSaveClickHandler = async () => {
    let changedInventory: IEditInventoryListingDatum[] = [];
    let updatedInventory: IEditInventoryListingDatum;
    setSaveChangesButtonEnabled(false);
    let messageKey = "updateListing";
    message.loading({
      content: `Saving listing...`,
      duration: 0,
      key: messageKey,
      className: "toastMsg loadingMsg",
    });
    setAllZoneListings((prev) => {
      if (prev) {
        let _prev = { ...prev };
        _prev.events?.forEach((eachEvent) => {
          const newListings = eachEvent?.po_details?.editedActiveListings;
          eachEvent?.po_details?.activeListings?.map((each) => {
            const newData = newListings?.find(
              (listing) => listing.inventory_id === each.inventory_id
            );
            if (
              newData &&
              each &&
              (each.list_price !== newData.list_price ||
                each.section !== newData.section ||
                each.row !== newData.row)
            ) {
              newData.is_updating = true;
            }
          });
        });
        return _prev;
      }
      return prev;
    });
    allZoneListings?.events?.forEach((eachEvent) => {
      const oldListings = eachEvent?.po_details?.activeListings;
      eachEvent?.po_details?.editedActiveListings?.map((each) => {
        const oldData = oldListings?.find(
          (listing) => listing.inventory_id === each.inventory_id
        );
        if (
          oldData &&
          each &&
          (each.list_price !== oldData.list_price ||
            each.section !== oldData.section ||
            each.row !== oldData.row)
        ) {
          updatedInventory = {
            inventory_id: oldData.inventory_id,
            inventory_ids: oldData.inventory_ids,
            is_split: oldData.is_split,
            oldSection: oldData.section,
            newSection: each.section,
            oldRow: oldData.row,
            newRow: each.row,
            old_list_price: oldData.list_price,
            new_list_price: each.list_price,
            update_price: each.list_price !== oldData.list_price,
            update_section_row:
              each.section !== oldData.section || each.row !== oldData.row,
          };
          changedInventory.push(updatedInventory);
        }
      });
    });
    let _inventories: number[] = [];
    changedInventory.forEach((eachInventory) => {
      if (eachInventory.is_split) {
        _inventories.push(...eachInventory.inventory_ids);
      } else {
        _inventories.push(eachInventory.inventory_id);
      }
    });
    try {
      const updateResponse =
        await new ZonesService().updateListingSectionRowPrice(changedInventory);
      setAllZoneListings((prev) => {
        if (prev) {
          let _prev = { ...prev };
          _prev.events?.forEach((eachEvent) => {
            const newListings = eachEvent?.po_details?.editedActiveListings;
            eachEvent?.po_details?.activeListings?.map((each) => {
              const newData = newListings?.find(
                (listing) => listing.inventory_id === each.inventory_id
              );
              if (
                newData &&
                each &&
                (each.list_price !== newData.list_price ||
                  each.section !== newData.section ||
                  each.row !== newData.row)
              ) {
                newData.is_updating = false;
              }
            });
          });
          return _prev;
        }
        return prev;
      });
      let forceUpdateMessage = "Syncing data from skybox to platform...";
      let messageDuration = 5;
      if (updateResponse.status === 200) {
        message.success({
          content: `Listing Updated in Skybox.`,
          duration: 5,
          key: messageKey,
          className: "toastMsg savedSuccess",
        });
        messageKey = "forceUpdate";
        setAllZoneListings((prev) => {
          if (prev) {
            const _prev = { ...prev };
            _prev?.events?.forEach((eachEvent) => {
              if (eachEvent && eachEvent.po_details) {
                eachEvent.po_details.activeListings = JSON.parse(
                  JSON.stringify(eachEvent?.po_details?.editedActiveListings)
                );
              }
            });
            return _prev;
          }
          return prev;
        });
        message.loading({
          content: forceUpdateMessage,
          duration: 0,
          key: messageKey,
          className: "toastMsg loadingMsg",
        });
        const forceUpdateResponse =
          await new ZonesService().forceUpdatePlatformSkyboxInventories(
            _inventories
          );
        if (forceUpdateResponse.data.data.is_mvw_refreshed) {
          forceUpdateMessage = "Zone listing has been synced successfully!";
        } else {
          forceUpdateMessage =
            "The Listing has been updated successfully but it may take a while to appear on the platform. Data synchronization with SkyBox is in progress.";
          messageDuration = 10;
        }
      }
      message.success({
        content: forceUpdateMessage,
        duration: messageDuration,
        key: messageKey,
        className: "toastMsg savedSuccess",
      });

      allZoneListings?.events?.forEach(async (eachEvent) => {
        const oldListings = eachEvent?.po_details?.activeListings;
        eachEvent?.po_details?.editedActiveListings?.map(async (each) => {
          const oldData = oldListings?.find(
            async (listing) => listing.inventory_id === each.inventory_id
          );
          if (
            oldData &&
            each &&
            (each.list_price !== oldData.list_price ||
              each.section !== oldData.section ||
              each.row !== oldData.row)
          ) {
            await new ZonesService().remapListingsWithZones(oldData.event_id); //remap listing with zone bts
          }
        });
      });
    } catch (error: any) {
      message.error({
        key: messageKey,
        content: "Failed to refresh data.",
        duration: 5,
        className: "toastMsg savedFailed",
      });

      new LoggerService().log({
        payload: error,
        function_name: "allSaveClickHandler",
      });

      setSaveChangesButtonEnabled(true);
    }
  };

  const discardEdits = () => {
    setAllZoneListings((prev) => {
      if (prev) {
        let _prev = { ...prev };
        const _events = _prev.events;
        _events.forEach((eachEvent) => {
          const _po_details = eachEvent.po_details;
          if (
            _po_details?.activeListings &&
            _po_details?.editedActiveListings
          ) {
            if (
              JSON.stringify(_po_details.activeListings) !==
              JSON.stringify(_po_details.editedActiveListings)
            ) {
              _po_details.editedActiveListings = JSON.parse(
                JSON.stringify(_po_details.activeListings)
              );
            }
          }
        });
        return _prev;
      }
      return prev;
    });
  };

  const onApplyFilterWithValuesHandler = useCallback(
    (e: any) => {
      setFilterAppliedWithValues(e);
    },
    [filtersAppliedWithValues]
  );

  return (
    <React.Fragment>
      <div className="mainHeading min-h-40">
        <Row className="flx-1">
          <Col xs={18} sm={8} md={8} xl={5} className="mb12">
            <h2 id="inventoryReportTables">All Zone Listings</h2>
          </Col>
          <Col xs={6} sm={4} md={4} xl={2} className="switchAlignment">
            <Tooltip
              title={
                viewMode === "listView"
                  ? "Toggle Card View"
                  : "Toggle List View"
              }
            >
              {
                <Switch
                  className="gridListView"
                  defaultChecked
                  onChange={viewToggleHandler}
                  checkedChildren={<i className="da icon-th-list"></i>}
                  unCheckedChildren={<i className="da icon-th-large"></i>}
                />
              }
            </Tooltip>
          </Col>
          <Col xs={24} sm={12} md={12} xl={5}>
            <div className="search">
              <form onSubmit={handleTextSearch}>
                <input
                  type="search"
                  placeholder="Search Event, Venue, Broker, Lister, Po no..."
                  value={searchText ?? ""}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText && searchText.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <ul className="innerHeaderRight min-h-40 ctaGroup">
            <li>
                      <Button
                         className="cancelStyle2"
                        type="text"
                        style={
                          !saveChangesButtonEnabled
                            ? {
                                marginRight: "0px"
                              }
                            : { marginRight: "0px"}
                        }
                        disabled={!saveChangesButtonEnabled}
                        onClick={() =>
                          showConfirm(
                            "",
                            getDiscardChangesConfirmBody(),
                            {},
                            discardEdits,
                            cancelOperationHandler
                          )
                        }
                      >
                        {/* <i className="da icon-cancel"></i> */}
                        Discard<br></br>Changes
                      </Button>
                    </li>
                    <li>
                      <Button
                      type="primary"
                        disabled={!saveChangesButtonEnabled}
                        onClick={() =>
                          showConfirm(
                            "",
                            getSaveChangesConfirmBody(),
                            {},
                            allSaveClickHandler,
                            cancelOperationHandler
                          )
                        }
                      >
                        {/* <i className="da icon-save-floppy-w"></i> */}
                        Save<br></br>Changes 
                      </Button>
                    </li>

              <RBAC
                allowedPermissions={[
                  ERbacPermissions.ZONES_ACTIVE_LISTING_BROADCAST_ACTION,
                ]}
              >
                {viewMode === "listView" && (
                  <>
                    
                    <li>
                      <Button
                        type="primary"
                        disabled={!isUnbroadcastButtonEnabled}
                        onClick={() =>
                          showConfirm(
                            "",
                            getBroadcastSelectedConfirmBody(),
                            {},
                            confirmUnbroadcastSelectedHandler,
                            cancelOperationHandler
                          )
                        }
                      >
                        {/* <i className="da icon-unbroadcast-selected"></i> */}
                        Unbroadcast<br></br>Selected
                      </Button>
                    </li>
                    <RBAC
                      allowedPermissions={[
                        ERbacPermissions.ZONES_ACTIVE_LISTING_UNBROADCAST_FILTERED_ACTION,
                      ]}
                    >
                      <li>
                        <Button
                          type="primary"
                          disabled={!isUnbroadcastFilteredEnabled}
                          onClick={() =>
                            showConfirm(
                              "",
                              getBroadcastedFilteredConfirmBody(),
                              {},
                              () =>
                                confirmUnbroadcastFilteredHandler(
                                  allZoneListings?.broadcasted_po_lines
                                    ? allZoneListings?.broadcasted_po_lines
                                    : []
                                ),
                              cancelOperationHandler
                            )
                          }
                        >
                          {/* <i className="da icon-unbroadcast-filtered"></i> */}
                          Unbroadcast<br></br>Filtered
                        </Button>
                      </li>
                    </RBAC>
                    
                  </>
                )}
              </RBAC>
              <li>
                {isPageReady && (
                  <FilterDrawer
                    disabled={
                      isWidgetFilterParams && isFilterDrawerDisabled
                        ? true
                        : false
                    }
                    defaultAndValues={{
                      preFilledValues: { ...filterQueryObject },
                    }}
                    onApplyFilter={filterChanged}
                    showEventDatePicker={true}
                    showBrokerUserDropdown={hasPermissions([
                      ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                    ])}
                    brokerUserDropdownType="single"
                    showListerDropdown={hasPermissions([
                      ERbacPermissions.FILTER_LISTER_USER_SELECTION_ALL,
                      ERbacPermissions.FILTER_LISTER_USER_SELECTION_TEAM
                    ])}
                    listerDropdownType="single"
                    showStateDropdown={true}
                    stateDropdownType="single"
                    onApplyFilterWithValues={onApplyFilterWithValuesHandler}
                    showPricingIssuesCheckbox={true}
                    showTbPlacementCheckbox={true}
                    showPendingFillsCheckbox={true}
                    showHighRiskListingsOnlyCheckbox={true}
                    showLowAvailabilityListingsOnlyCheckbox={true}
                    showBroadcastedCheckbox={true}
                    showTagMissingCheckbox={hasPermissions([
                      ERbacPermissions.FILTER_SHOW_MISSING_TAG
                    ])}
                  />
                )}
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      <AllZoneListingsComponent
        setIsUnbroadcastFilteredEnabled={setIsUnbroadcastFilteredEnabled}
        isWidgetFilterParams={isWidgetFilterParams}
        urlParams={urlParams}
        searchText={searchText}
        setSearchText={setSearchText}
        getHeightOfAllZoneListings={getHeightOfAllZoneListings}
        queryParams={queryParams}
        filtersAppliedWithValues={filtersAppliedWithValues}
        setQueryParams={setQueryParams}
        viewMode={viewMode}
        allZoneListings={allZoneListings}
        setAllZoneListings={setAllZoneListings}
        purchaseLineId={purchaseLineId}
      />
    </React.Fragment>
  );
};

export default ZoneListingsPage;
